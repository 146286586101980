








































































































































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import vSelect from 'vue-select';
import Deselect from './Deselect.vue';
import OpenIndicator from './OpenIndicator.vue';

export interface SelectOption {
  label: string;
  value: string | number;
}

export type SelectOptions = SelectOption[];
export type SelectValue = SelectOption | null;
export type SelectMultipleValue = SelectOption | SelectOptions | null;

let id = 0;

@Component({
  components: {
    vSelect,
  },
})
export default class USelectField extends Vue {
  @Prop({type: [Object, Array], default: null})
  private value!: SelectValue | SelectMultipleValue;

  @Prop({type: String})
  private label!: string;

  @Prop({type: Array, default: () => ([])})
  private options!: SelectOptions;

  @Prop({type: Boolean, default: false})
  private disabled!: boolean;

  @Prop({type: Boolean, default: true})
  private clearable!: boolean;

  @Prop({type: Boolean, default: true})
  private searchable!: boolean;

  @Prop({type: Boolean, default: false})
  private multiple!: boolean;

  @Prop({type: Boolean, default: true})
  private closeOnSelect!: boolean;

  @Prop({type: Boolean, default: false})
  private taggable!: boolean;

  @Prop({type: [String, Array], default: ''})
  private error!: string | string[];

  @Prop({type: Boolean, default: false})
  private required!: boolean;

  @Prop({type: Boolean, default: false})
  private native!: boolean;

  private id: string;
  private valueLocal: SelectValue | SelectMultipleValue = this.value;
  private isOpen = false;

  constructor() {
    super();
    id++;
    this.id = `sf-${id}`;
  }

  @Watch('value')
  private onValueChanged(val: SelectValue) {
    this.valueLocal = val;
  }

  @Watch('valueLocal')
  private onValueLocalChanged(val: SelectValue) {
    this.valueLocal = val;
    this.$emit('input', val);
    this.$emit('update:error', undefined);
  }

  private get errorMessages(): string[] {
    let res: string[] = [];

    if (Array.isArray(this.error)) {
      res = this.error;
    } else if (this.error) {
      res = [this.error];
    }

    return res;
  }

  private get hasError(): boolean {
    return this.errorMessages.length > 0;
  }

  private get hasValue(): boolean {
    if (Array.isArray(this.valueLocal)) {
      return this.valueLocal.length > 0;
    }

    return !!this.valueLocal;
  }

  private get classNames() {
    return ['u-select-field', {
      'u-select-field__disabled': this.disabled,
      'u-select-field__has-value': this.hasValue,
      'u-select-field__is-open': this.isOpen,
      'u-select-field__has-error': this.hasError,
    }];
  }

  private get components() {
    return {
      Deselect,
      OpenIndicator,
    };
  }

  private get valueNative(): string | number {
    if (Array.isArray(this.valueLocal)) {
      return this.valueLocal.length ? this.valueLocal[0].value : '';
    } else if (this.valueLocal) {
      return this.valueLocal.value;
    } else {
      return '';
    }
  }

  private set valueNative(val: string | number) {
    this.valueLocal = this.options.find((o) => o.value.toString() === val.toString()) || null;
  }

  private onSearch(val: string, loading: (l: boolean) => void) {
    this.$emit('search', val, loading);
  }
}
