/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'headphones': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M360-120H200q-33 0-56.5-23.5T120-200v-280q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480v280q0 33-23.5 56.5T760-120H600v-320h160v-40q0-117-81.5-198.5T480-760q-117 0-198.5 81.5T200-480v40h160v320zm-80-240h-80v160h80v-160zm400 0v160h80v-160h-80zm-400 0h-80 80zm400 0h80-80z"/>'
  }
})
