export enum OrderDir {
  asc = 'asc',
  desc = 'desc',
}

export interface ListResponse<T> {
  total: number;
  list: T[];
}

export * from './AuthService';
export * from './TrackDistributionService';
export * from './TrackService';
export * from './GenreService';
export * from './CompilationService';
export * from './PresetService';
export * from './CombinationService';
export * from './PlaylistService';
export * from './TagService';
