/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_up': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M720-120H280v-520l280-280 50 50q7 7 11.5 19t4.5 23v14l-44 174h258q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14zm-360-80h360l120-280v-80H480l54-220-174 174v406zm0-406v406-406zm-80-34v80H160v360h120v80H80v-520h200z"/>'
  }
})
