


















































































































































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import {Route} from "vue-router";

export interface TableField {
  name: string;
  label: string;
  width?: string;
  align?: 'center' | 'left' | 'right';
  sortable?: boolean;
}

type RouteQuery = Record<string, string | undefined>;

@Component
export default class UTable extends Vue {
  @Prop({required: true, type: Array})
  // eslint-disable-next-line
  private readonly data!: Record<string, any>[];

  @Prop({required: true, type: Array})
  private readonly fields!: TableField[];

  @Prop({required: false, type: Boolean})
  private readonly sticky!: boolean;

  private localSortOrder: 'asc' | 'desc' = 'desc';
  private localSortField = '';

  private get tableFields() {
    return this.fields;
  }

  private get tableData() {
    return this.data;
  }

  private getFieldTitle(field: TableField) {
    return field.label;
  }

  private getFieldName(field: TableField) {
    return field.name;
  }

  // eslint-disable-next-line
  private getItemFieldData(item: any, field: TableField) {
    return item[this.getFieldName(field)];
  }

  private getThClassNames(field: TableField) {
    const res = ['u-table_th', {
      'sortable': this.getFieldSortable(field),
      'sortable-active': this.getFieldSortableActive(field),
    }];

    if ('align' in field) {
      res.push('u-table_th__' + field.align);
    }

    return res;
  }

  private getThStyles(field: TableField) {
    const res: Record<string, string> = {};

    if (field.width) {
      res.width = field.width;
    }

    return res;
  }

  private getTdClassNames(field: TableField) {
    const res = ['u-table_td'];

    if ('align' in field) {
      res.push('u-table_td__' + field.align);
    }

    return res;
  }

  private getFieldSortable(field: TableField): boolean {
    return !!field.sortable;
  }

  private orderBy(field: TableField) {
    if (!this.getFieldSortable(field)) {
      return;
    }

    this.singleColumnSort(field);
  }

  private routePush(query: RouteQuery) {
    this.$router.push({
      name: this.$route.name || '',
      params: this.$route.params,
      query: {
        ...this.$route.query,
        ...query,
      },
    });
  }


  @Watch('$route', {immediate: true})
  private routeChanged(route: Route) {
    const {sortField, sortOrder} = route.query;

    this.localSortField = 'id';
    this.localSortOrder = 'desc';

    if (sortField) {
      this.localSortField = sortField.toString();
    }

    if (sortOrder) {
      const sortOrderStr = sortOrder.toString();

      if (sortOrderStr === 'asc' || sortOrderStr === 'desc') {
        this.localSortOrder = sortOrderStr;
      }
    }
  }

  private getInverseSorterOrder(sortOrder: string) {
    return sortOrder === 'asc' ? 'desc' : 'asc';
  }

  private singleColumnSort(field: TableField) {
    const oldSortField = this.localSortField;
    const oldSortOrder = this.localSortOrder;

    const newSortField = this.getFieldName(field);
    const newSortOrder = oldSortField === newSortField
            ? this.getInverseSorterOrder(oldSortOrder) : 'desc';

    this.routePush({
      sortField: newSortField,
      sortOrder: newSortOrder,
    });
  }


  private getIconSortDir(field: TableField) {
    if (this.getFieldSortableActive(field)) {
      return this.localSortOrder === 'asc' ? 'up' : 'down';
    }

    return 'up';
  }

  private getFieldSortableActive(field: TableField): boolean {
    return this.getFieldName(field) === this.localSortField;
  }

  private thClickHandler(field: TableField) {
    if (this.getFieldSortable(field)) {
      this.orderBy(field);
    }
  }
}
