/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_down': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14zm360 80H240L120-480v80h360l-54 220 174-174v-406zm0 406v-406 406zm80 34v-80h120v-360H680v-80h200v520H680z"/>'
  }
})
