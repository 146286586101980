







































import {Component, Vue} from 'vue-property-decorator';
import UButton from "@/ui-lib/button/Button.vue";
import {TrackTag} from "@/types";
import USelectField, {SelectMultipleValue, SelectOptions} from "@/ui-lib/select-field/SelectField.vue";
import {OrderDir, TagOrderColumn, TagService} from "@/api";

export type TrackTagsSelectModalParams = {
  selected?: TrackTag[];
  onSelect?: (value: SelectMultipleValue) => Promise<void>;
}

@Component({
  components: {
    USelectField,
    UButton,
  },
})
export default class extends Vue {
  private selected: TrackTag[] = [];
  private onSelect: (value: SelectMultipleValue) => Promise<void> = (async () => {
    //
  });

  private isLoading = false;
  private options: SelectOptions = [];
  private abort: AbortController | null = null;
  private value: SelectMultipleValue = null;

  private close(): void {
    this.$modal.hide('track-tags-select');
  }

  private beforeOpen({params}: { params: TrackTagsSelectModalParams }) {
    if (this.abort) {
      this.abort.abort();
    }

    this.isLoading = false;
    this.options = [];
    this.selected = params?.selected || [];
    this.value = null;
    this.onSelect = params?.onSelect || (async () => {
      //
    });

    if (this.selected.length) {
      this.value = this.selected.map((tt) => ({
        label: tt.name,
        value: tt.id,
      }))
    }

    this.loadFirst();
  }

  private async select() {
    this.isLoading = true;

    try {
      const copiedValue = this.value ?
              (Array.isArray(this.value)
                      ? this.value.map((t) => {
                        return typeof t === "string" ? {label: t, value: t} : {...t};
                      })
                      : {...this.value})
              : null;
      await this.onSelect(copiedValue);
      this.close();
    } catch (e) {
      this.$notify({type: 'error', title: 'Ошибка', text: e?.message || 'Неизвестная ошибка'});
    }

    this.isLoading = false;
  }

  private async loadFirst() {
    this.isLoading = true;
    await this.load('')
    this.isLoading = false;
  }

  private async load(search: string) {
    try {
      this.abort = new AbortController();

      const res = await TagService.list({
        start: 0,
        limit: 20,
        search: search,
        order_column: TagOrderColumn.id,
        order_dir: OrderDir.desc,
      }, {signal: this.abort.signal});

      this.options = res.list.map((tag) => ({
        label: tag.name,
        value: tag.id,
      }))
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        // ok
      } else {
        this.$notify({type: 'error', title: 'Ошибка', text: e?.message || 'Неизвестная ошибка'});
      }
    }
  }

  private async onSearch(val: string, loading: (l: boolean) => void) {
    if (this.abort) {
      this.abort.abort();
    }

    if (!val) {
      this.options = [];
      return;
    }

    loading(true);
    await this.load(val);
    loading(false);
  }
}
