



















































































































import {Component, Vue} from 'vue-property-decorator';
import {Playlist} from "@/types";
import {PlaylistService, OrderDir, PlaylistListParams, PlaylistOrderColumn} from "@/api";
import {TableField} from "@/ui-lib/table/Table.vue";
import UPagination from "@/ui-lib/pagination/Pagination.vue";
import UTable from "@/ui-lib/table/Table.vue";
import {convertTimeHHMMSS} from "@/utils/convertTimeHHMMSS";
import {preset} from "@/store/modules/preset";
import UCheckbox from "@/ui-lib/checkbox/Checkbox.vue";
import UButton from "@/ui-lib/button/Button.vue";

export type PlaylistsSelectModalParams = {
  selected?: number[];
  onSelect?: () => void;
}

@Component({
  components: {
    UButton,
    UCheckbox,
    UPagination,
    UTable,
  },
})
export default class extends Vue {
  private selected: number[] = [];
  private onSelect: () => void = (() => {
    return;
  });

  private start = 0;
  private limit = 15;
  private isLoading = false;
  private list: Playlist[] = [];
  private total = 0;

  private close(): void {
    this.$modal.hide('playlists-select');
  }

  private beforeOpen({params}: { params: PlaylistsSelectModalParams }) {
    this.start = 0;
    this.isLoading = false;
    this.list = [];
    this.total = 0;
    this.selected = params?.selected || [];
    this.onSelect = params?.onSelect || (() => {
      return;
    });

    this.load();
  }

  private async load() {
    this.isLoading = true;

    try {
      const params: PlaylistListParams = {
        start: this.start,
        limit: this.limit,
        orderColumn: PlaylistOrderColumn.id,
        orderDir: OrderDir.desc,
      };

      const {list, total} = await PlaylistService.list(params);

      this.list = list;
      this.total = total;
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoading = false;
  }

  private get tableFields(): TableField[] {
    return [
      {
        name: 'select',
        label: '',
        width: '52px',
      },

      {
        name: 'id',
        label: 'ID',
        width: '65px',
      },

      {
        name: 'image',
        label: '',
        width: '60px',
      },

      {
        name: 'title',
        label: 'Название',
      },

      {
        name: 'musicPresetIDs',
        label: 'Пресеты',
        width: '170px',
      },

      {
        name: 'totalTracks',
        label: 'Треки',
        align: 'right',
        width: '100px'
      },

      {
        name: 'totalDuration',
        label: 'Длительность',
        align: 'right',
        width: '150px'
      },
    ];
  }

  private get presets() {
    return preset.context(this.$store).state.list;
  }

  private presetsName(pl: Playlist) {
    return this.presets.filter((g) => pl.musicPresetIDs.includes(g.id)).map((g) => g.name).join(', ') || 'Неизвестно';
  }

  private durationText(pl: Playlist) {
    return convertTimeHHMMSS(pl.totalDuration);
  }

  private changeStart(val: number) {
    this.start = val;
    this.load();
  }

  private select() {
    this.onSelect();
    this.close();
  }

  private toggleSelect(row: Playlist, val: boolean) {
    const i = this.selected.indexOf(row.id);

    if (val) {
      if (i === -1) {
        this.selected.push(row.id);
      }
    } else {
      if (i >= 0) {
        this.selected.splice(i, 1);
      }
    }
  }

  private selectAll() {
    this.list.forEach((item) => {
      const i = this.selected.indexOf(item.id);

      if (i === -1) {
        this.selected.push(item.id);
      }
    });
  }

  private get selectedList() {
    return this.list.filter((p) => this.selected.includes(p.id)).map((p) => p.id);
  }

  private unselectAll() {
    const selected = [...this.selectedList];

    selected.forEach((id) => {
      const i = this.selected.indexOf(id);

      if (i >= 0) {
        this.selected.splice(i, 1);
      }
    });
  }
}
