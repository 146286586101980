/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'skip_next': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M660-240v-480h80v480h-80zm-440 0v-480l360 240-360 240zm80-240zm0 90l136-90-136-90v180z"/>'
  }
})
