export interface Tag {
  id: number;
  name: string;
  trackCount: number;
}

export const createTag = (): Tag => ({
  id: 0,
  name: '',
  trackCount: 0,
})
