import {api, RequestOptions} from '@/api/api';
import {Tag, TrackTag} from '@/types';
import {Route} from "vue-router";
import {OrderDir} from "@/api/index";

export enum TagOrderColumn {
  id = 'id',
  name = 'name',
  trackCount = 'trackCount',
}

export interface TagServiceListParams {
  start?: number;
  limit?: number;
  order_column?: TagOrderColumn;
  order_dir?: OrderDir;
  search?: string;
  ids?: number[];
}

export interface TagServiceList {
  total: number;
  list: Tag[];
}

export const TagService = {
  prepareListParams(r: Route, defaultLimit: number): TagServiceListParams {
    const res: TagServiceListParams = {
      limit: defaultLimit,
      start: 0,
    };

    if (r.query.limit) {
      res.limit = parseInt(r.query.limit.toString(), 10);
    }

    if (r.query.start) {
      res.start = parseInt(r.query.start.toString(), 10);
    }

    switch (r.query.sortField) {
      case TagOrderColumn.id:
      case TagOrderColumn.name:
      case TagOrderColumn.trackCount:
        res.order_column = r.query.sortField;
    }

    switch (r.query.sortOrder) {
      case OrderDir.asc:
      case OrderDir.desc:
        res.order_dir = r.query.sortOrder;
        break;
    }

    if (r.query.search) {
      res.search = r.query.search.toString();
    }

    if (r.query.ids) {
      res.ids = r.query.ids.toString().split(',').map((id) => parseInt(id, 10));
    }

    return res;
  },

  list(params: TagServiceListParams, opts?: RequestOptions): Promise<TagServiceList> {
    // eslint-disable-next-line
    const query: Record<string, any> = {...params};

    if (params.ids) {
      query['ids'] = params.ids.join(',');
    }

    return api.get('/tag/list', query, opts);
  },

  syncTrack(track_id: number, tags: { name: string }[]): Promise<TrackTag[]> {
    return api.post('/tag/sync_track', {
      track_id, tags,
    });
  },

  attachTracks(track_ids: number[], tags: { name: string }[]): Promise<void> {
    return api.post('/tag/attach_tracks', {
      track_ids, tags,
    });
  },

  detachTracks(track_ids: number[], tag_ids: number[]): Promise<void> {
    return api.post('/tag/detach_tracks', {
      track_ids, tag_ids,
    });
  }
};
