/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_up_fill': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M720-120H320v-520l280-280 50 50q7 7 11.5 19t4.5 23v14l-44 174h218q32 0 56 24t24 56v80q0 7-1.5 15t-4.5 15L794-168q-9 20-30 34t-44 14zM240-640v520H80v-520h160z"/>'
  }
})
